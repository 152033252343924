import React from 'react'
import FlashDeals from '../comonents/flashDeals/FlashDeals'
import Home from '../comonents/mainPage/Home'

const Pages = ({productItems, cartItem, addToCart}) => {
  return (
    <>
    <Home cartItem={cartItem}/>
    <FlashDeals productItems={productItems} addToCart={addToCart}/>
    </>
  )
}

export default Pages
