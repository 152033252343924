import React from 'react'


const Head = () => {
  return (
    <>
    <section className='head'>
        <div className='container d_flex'>
            <div className='left row'>
            <i className="fa fa-phone"></i>
            <label>+0977459970</label>
            <i className="fa fa-envelope"></i>
            <label>dvphat97@gmail.com</label>
            </div>
            <div className='right row RText'>
                <label >Them FAQ's</label>
                <label >Need Helps</label>
                <span>🏳️‍⚧️</span>
                <label >VN</label>
                <span>🏳️‍⚧️</span>
                <label >USD</label>

            </div>
        </div>

    </section>
    </>
  )
}

export default Head
