import React from 'react'
import logo from '../assets/images/logo.svg'
import { Link } from 'react-router-dom'

const Search = ({cartItem}) => {
    window.addEventListener('scroll', function(){
        const search = document.querySelector('.search')
        search.classList.toggle('active', window.screenY >100)
    })
  return (
    <>
    <section className='search'>
        <div className='container c_flex'>
            <div className='logo with'>
               <img src={logo} alt="" />
            </div>
            <div className='search-box f_flex'>
                <i className='fa fa-search'></i>
                <input type="text" placeholder='Search and hit enter...' />
                <span>All Category</span>
            </div>
            <div className='icon f_flex width'>
          
                <div className="cart">
                <i className='fa fa-user icon-circle'></i>
                <Link to='/cart'>
                    <i className='fa fa-shopping-bag icon-circle'></i>   
                    
                    
                </Link>
                <span>{cartItem.length === 0 ? ' ' :cartItem.length}</span>
                
               
                 </div>
                 
            </div>
            

        </div>

    </section>
    </>
  )
}

export default Search
