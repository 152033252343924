import React from 'react'
import SlideCart from './SlideCart'

const SliderHome = () => {
  return (
    <>
    <section className='homeSlide contentWidth'>
    <div className="container">
       <SlideCart />
    </div>
    </section>
    </>
  )
}

export default SliderHome
