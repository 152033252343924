import React ,{useState} from 'react';
import './App.css';
import Header from './common/Header/Header';
import Pages from './pages/Pages';
import {
  BrowserRouter as Router,
  Switch,Route
} from "react-router-dom";
import Data from './comonents/flashDeals/Data';
import Cart from './common/Cart/Cart';
function App() {
  const {productItems} = Data;
  const [cartItem, setCartItem] = useState([])
  const addToCart = (product) => {
const productExit = cartItem.find((item) => item.id===product.id)
  
  if (productExit) {
    setCartItem(cartItem.map((item) => 
    (item.id === product.id ? { ...productExit, qty: productExit.qty + 1 } 
      : item)))
  }

  else {
  
    setCartItem([...cartItem, {...product, qty: 1 }])
  }
}
console.log('addToCard')
  return (
   <>
  <Router>
  <Header cartItem={cartItem} />
         <Switch>
          <Route path="/" exact>
            <Pages productItems={productItems} addToCart={addToCart} />
          </Route>
          <Route path='/cart' exact>
            <Cart cartItem={cartItem} addToCart={addToCart}  />
          </Route>
        </Switch>
    </Router>
   </>
  );
  }

export default App;
